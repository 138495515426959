<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title" v-if="selectedLevel === 'zorgkantoor' || selectedLevel === 'dementienetwerk'">2.
          Langdurige zorg mensen met dementie in {{ selectedYear }}</h1>
        <h1 class="intro-section-title" v-if="selectedLevel === 'landelijk'">2. Langdurige zorg mensen met dementie</h1>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Tijd tussen Wlz-indicatie en verhuizing zorginstelling in ${selectedYear}`"
          :hovertext="'Er wordt uitgegaan van de eerste indicatie. Om te bepalen of een indicatie de eerste was worden ook indicaties voor zorg met verblijf die afgegeven zijn in de periode 2012-2014 meegenomen.'"
          :x="zorgkantoorregionaam_tijdtotopname" :y="categorie_tijdtotopname"
          :z="percentage_tijdtotopname.map(value => `${value}%`)" :xtitle="'Jaar'" :ytitle="'Categorie'"
          :ztitle="'Percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 4000">
          <template slot="card-header">Tijd tussen Wlz-indicatie en verhuizing zorginstelling in
            {{ selectedYear }}</template>
          <div slot="card-body">
            Onderstaande grafiek geeft de (wacht)tijd weer tussen de indicatie en de verhuizing naar een zorginstelling
            voor
            mensen met dementie die voor het eerst een indicatie kregen voor zorg met verblijf. Mensen die vóór opname
            overleden
            vallen in de groep "geen opname". Op landelijk niveau is te zien dat het percentage mensen dat binnen 42
            dagen
            opgenomen wordt vanaf 2017 afneemt. Deze informatie is alleen op zorgkantoor beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphThree" :ytitle="'% aantal dagen'"
              :names="['0-42 dagen', '43-91 dagen', '92-180 dagen', '181-365 dagen', '365+ dagen/geen opname']"
              :x="[zorgkantoorregio42, zorgkantoorregio91, zorgkantoorregio180, zorgkantoorregio365, zorgkantoorregio366n]"
              :y="[dagen42, dagen91, dagen180, dagen365, dagen366n]" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="`Tijd tussen Wlz-indicatie en verhuizing zorginstelling`"
          :hovertext="'Er wordt uitgegaan van de eerste indicatie. Om te bepalen of een indicatie de eerste was worden ook indicaties voor zorg met verblijf die afgegeven zijn in de periode 2012-2014 meegenomen.'">
          <template slot="card-header">Tijd tussen Wlz-indicatie en verhuizing zorginstelling - uitsluitend op
            zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>


    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz Verblijf in ${selectedYear}`"
          :hovertext="'Bij het bepalen of iemand is opgenomen in een Wlz-instelling is gebruik gemaakt van de gegevens van het CAK (GEBWLZTAB), waarbij de opnamedatum is gedefinieerd als de datum waarop de levering van zorg in natura in een instelling is gestart (en gebruik van andere leveringsvormen dus wordt genegeerd). Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_natura" :y="percentage_natura_table.map(value => `${value}%`)" :xtitle="'Regio naam'"
          :ytitle="'Percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
          <template slot="card-header">Leveringsvorm Wlz Verblijf in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz-zorg met verblijf ontvangt ten
            opzichte
            van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op zorgkantoorregio-niveau
            beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% Wlz met verblijf'" :names="['Natura']"
              :x="[zorgkantoorregionaam_natura]" :y="[percentage_natura]" :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz Verblijf'"
          :hovertext="'Bij het bepalen of iemand is opgenomen in een Wlz-instelling is gebruik gemaakt van de gegevens van het CAK (GEBWLZTAB), waarbij de opnamedatum is gedefinieerd als de datum waarop de levering van zorg in natura in een instelling is gestart (en gebruik van andere leveringsvormen dus wordt genegeerd). Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">Leveringsvorm Wlz Verblijf - uitsluitend op zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz volledig pakket thuis in ${selectedYear}`"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_vpt" :y="percentage_volledig_pakket_thuis_table.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 2000 : 1000">
          <template slot="card-header">Leveringsvorm Wlz volledig pakket thuis in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van volledig
            pakket
            thuis ten opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op
            zorgkantoorregio-niveau beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met volledig pakket thuis'"
              :names="['Volledig pakket thuis']" :x="[zorgkantoorregionaam_vpt]" :y="[percentage_volledig_pakket_thuis]"
              :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz volledig pakket thuis'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">
            Leveringsvorm Wlz volledig pakket thuis - uitsluitend op zorgkantoorregio-niveau
          </template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz modulair pakket thuis in ${selectedYear}`"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_mpt" :y="percentage_modulair_pakket_thuis_table.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 2000 : 1000">
          <template slot="card-header">Leveringsvorm Wlz modulair pakket thuis in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van modulair
            pakket
            thuis ten opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op
            zorgkantoorregio-niveau beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met modulair pakket thuis'"
              :names="['Modulair pakket thuis']" :x="[zorgkantoorregionaam_mpt]" :y="[percentage_modulair_pakket_thuis]"
              :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz modulair pakket thuis'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">
            Leveringsvorm Wlz modulair pakket thuis - uitsluitend op zorgkantoorregio-niveau
          </template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor'">
      <div class="col-sm-12 down">
        <card :cardId="`Leveringsvorm Wlz PGB in ${selectedYear}`"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="zorgkantoorregionaam_pgb" :y="percentage_pgb_table.map(value => `${value}%`)" :xtitle="'Regio naam'"
          :ytitle="'Percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 2000 : 1000">
          <template slot="card-header">Leveringsvorm Wlz PGB in {{ selectedYear }}</template>
          <div slot="card-body">
            In dit overzicht is het percentage mensen met dementie te zien dat Wlz ontvangt in de vorm van een PGB ten
            opzichte van het totaal aantal mensen dat Wlz ontvangt. Deze informatie is uitsluitend op
            zorgkantoorregio-niveau beschikbaar.
            <stacked-bar-chart-colors v-if="load.graphOne" :ytitle="'% met PGB'" :names="['PGB']"
              :x="[zorgkantoorregionaam_pgb]" :y="[percentage_pgb]" :ticksuffix="'%'" :showlegend="false" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Leveringsvorm Wlz PGB'"
          :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
          <template slot="card-header">Leveringsvorm Wlz PGB - uitsluitend op zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz-zorg over tijd'"
          :hovertext="'De onderstaande grafiek geeft percentages weer voor specifieke leveringsvormen ten opzichte van het aantal mensen met dementie dat Wlz ontvangt. De data over de mensen met dementie komt uit de cbs microdata.'"
          :x="jaar" :y="categorie_pakket_thuis" :z="percentage_pakket_thuis_table.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Categorie'" :ztitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 300 : 300">
          <template slot="card-header">Leveringsvorm Wlz-zorg over tijd – nationale cijfers</template>
          <div slot="card-body">
            In deze grafiek worden de verschillende leveringsvormen op nationaal niveau over tijd weergegeven als
            percentage
            van het totaal aantal mensen met dementie dat enige vorm van Wlz ontvangt. Er is een trend te zien voor de
            leveringsvorm Wlz met verblijf. Over de jaren heen wordt hier minder gebruik van gemaakt. Daarnaast is er
            over
            de jaren heen juist een stijging te zien in het gebruik van een modulair pakket thuis of de leveringsvorm
            PGB.
            <stacked-bar-chart-colors :ytitle="'% met leveringsvorm'" :ticksuffix="'%'" v-if="load.graphTwo"
              :names="['Verblijf', 'Volledig pakket thuis', 'Modulair pakket thuis', 'PGB Wlz']"
              :x="[jaar, jaar, jaar, jaar]" :tickvals="[2017, 2018, 2019, 2020]"
              :y="[verblijf_j, volledig_pakket_thuis_j, modulair_pakket_thuis_j, pgb_j]"
              :ticktext="['2017', '2018', '2019', '2020']" :tickangle="0" />
          </div>
        </card>
      </div>
    </div>

    <!--     <div class="row">
      <div class="col-sm-12 down">
        <card :cardId="'factsheet2-7'" :hovertext="'De onderstaande grafiek geeft percentages weer voor specifieke leveringsvormen ten opzichte van het aantal mensen (met dementie of in een bepaalde leeftijdsklasse van de algemene bevolking) dat Wlz ontvangt. De data over de mensen met dementie komt uit de cbs microdata. De data over de algemene bevolking komt uit: https://mlzopendata.cbs.nl/#/MLZ/nl/dataset/40055NED/table?dl=1F1DA&ts=1645170709155'">
          <template slot="card-header">Leveringsvorm Wlz-zorg verschillende bevolkingsgroepen</template>
          <div slot="card-body">
            In onderstaand diagram worden de leveringsvormen weergegeven die gebruikt worden door verschillende bevolkingsgroepen. Hierin wordt een vergelijking gemaakt tussen de mensen met dementie en twee verschillende leeftijdsgroepen van de totale bevolking. De verdeling van de leveringsvormen is over de bevolkingsgroepen gelijk.
              <grouped-bar-chart :ytitle="'% met leveringsvorm'" :tickformat="',.0%'" v-if="load.graphFour" 
              :tickvals="[0,1,2,3]"
              :names="['Verblijf','Volledig pakket thuis','Modulair pakket thuis','PGB Wlz']"
              :x="[bevolkingsgroep,bevolkingsgroep,bevolkingsgroep,bevolkingsgroep]"
              :y="[verblijf,volledig_pakket_thuis,modulair_pakket_thuis,pgb]"
              />
          </div>
        </card>
      </div>
    </div> -->

    <div class="row" v-if="selectedLevel === 'zorgkantoor' && selectedYear !== 2020">
      <div class="col-sm-12 down">
        <card :cardId="`Tijd tussen opname en overlijden in ${selectedYear}`"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'"
          :x="zorgkantoorregionaam_tijd" :y="categorie_tijd" :z="percentage_tijd.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Categorie'" :ztitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 2000 : 3000">
          <template slot="card-header">Tijd tussen opname en overlijden in {{ selectedYear }}</template>
          <div slot="card-body">
            In onderstaand diagram wordt de tijd tussen opname en overlijden weergeven. Er worden verschillende
            tijdsgroepen
            gebruikt. Deze informatie is alleen op zorgkantoor beschikbaar. De verdeling van mensen met dementie over
            die
            groepen blijft redelijk constant over de jaren heen. Wel zijn er verschillen te zien tussen zorgkantoren.
            <stacked-bar-chart-colors v-if="load.graphFive" :ytitle="'% aantal dagen'"
              :names="['0-90 dagen', '91-180 dagen', '181-365 dagen', '366-730 dagen', '731+ dagen/niet overleden']"
              :x="[zorgkantoorregionaam_90, zorgkantoorregionaam_180, zorgkantoorregionaam_365, zorgkantoorregionaam_730, zorgkantoorregionaam_730]"
              :y="[dagen_90, dagen_180, dagen_365, dagen_730, dagen_731n]" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'dementienetwerk'">
      <div class="col-sm-12 down">
        <card-small :cardId="'Tijd tussen opname en overlijden dementienetwerk'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'">
          <template slot="card-header">Tijd tussen opname en overlijden - uitsluitend op
            zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'zorgkantoor' && selectedYear === 2020">
      <div class="col-sm-12 down">
        <card-small :cardId="'Tijd tussen opname en overlijden'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'">
          <template slot="card-header">Tijd tussen opname en overlijden</template>
          <div slot="card-body">
            <p>Deze grafiek is nog niet beschikbaar voor 2020 doordat de informatie over opnames en overlijden nog niet
              volledig is.</p>
          </div>
        </card-small>
      </div>
    </div>
    <!--     <div class="row">
      <div class="col-sm-12 down">
        <card :cardId="'factsheet2-9'" v-if="selectedLevel==='zorgkantoor'" :hovertext="'Van iedereen die een indicatie heeft gekregen in jaar x, hoeveel % is binnen 6 manden na indicatie overleden die niet opgenomen is geweest. De noemer is iedereen met een nieuwe indicatie in jaar x zonder opname. De teller is iedereen met een nieuwe indicatie in jaar x, zonder opname, overleden binnen 6 maanden na indicatie. De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'">
          <template slot="card-header">Overleden 6 maanden voor opname in {{selectedYear}}</template>
          <div slot="card-body">
              <p class="graphtext">In deze grafiek wordt het percentage mensen met dementie weergegeven dat overlijdt in de 6 maanden voor opname. Van iedereen die een indicatie heeft gekregen in jaar x, hoeveel % is binnen 6 manden na indicatie overleden die niet opgenomen is geweest. In onderstaand diagram is te zien hoe het geselecteerde zorgkantoor zich verhoudt tot het landelijk gemiddelde. Deze informatie is alleen op zorgkantoor beschikbaar. </p>
                <stacked-bar-chart-colors v-if="load.graphSix"
                :ytitle="'% overleden binnen 6 maanden'"
                :names="['%Overleden voor opname']"
                :x="[zorgkantoorregionaamovo]"
                :y="[perc_ovo]"
                :showlegend="false"
                :ticksuffix="'%'"/>
          </div>
        </card>
        <card-small :cardId="'factsheet2-9-1'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'Van iedereen die een indicatie heeft gekregen in jaar x, hoeveel % is binnen 6 manden na indicatie overleden die niet opgenomen is geweest. De noemer is iedereen met een nieuwe indicatie in jaar x zonder opname. De teller is iedereen met een nieuwe indicatie in jaar x, zonder opname, overleden binnen 6 maanden na indicatie. De data die wordt gebruikt voor deze grafiek komt uit de database met CBS microdata'">
          <template slot="card-header">Overleden 6 maanden voor opname - uitsluitend op zorgkantoorregio-niveau</template>
        </card-small>
      </div>
    </div> -->

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="factsheet2-landelijk-1">
        <card :cardId="'Tijd tussen Wlz-indicatie en verhuizing zorginstelling'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="jaar_tijdtotopname" :y="categorie_tijdtotopname" :z="percentage_tijdtotopname.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Categorie'" :ztitle="'Percentage'" :tableHeight="600">
          <template slot="card-header">Tijd tussen Wlz-indicatie en verhuizing zorginstelling</template>
          <div slot="card-body">
            In onderstaande grafiek is het landelijk gemiddelde weergegeven van de (wacht)tijd tussen de indicatie en de
            verhuizing naar een zorginstelling voor mensen met dementie die voor het eerst een indicatie kregen voor
            zorg
            met verblijf. Mensen die vóór ziekenhuisopname zijn overleden, vallen in de groep 'geen ziekenhuisopname'.
            Op
            landelijk niveau is het percentage mensen dat binnen 42 dagen wordt opgenomen sinds 2017 gedaald. Deze
            informatie is alleen beschikbaar op het zorgkantoor.
            <stacked-bar-chart-colors-multi v-if="load.graphSeven" :tickangle="0" :isLandelijk='false' :height="360"
              :ytitle="'% aantal dagen'" :name5="'0-42 dagen'" :x5="zorgkantoorregio42_land"
              :xx5="jaar_tijdtotopname_42_land" :y5="dagen42_land" :name4="'43-91 dagen'" :x4="zorgkantoorregio91_land"
              :xx4="jaar_tijdtotopname_91_land" :y4="dagen91_land" :name3="'92-180 dagen'"
              :x3="zorgkantoorregio180_land" :xx3="jaar_tijdtotopname_180_land" :y3="dagen180_land"
              :name2="'181-365 dagen'" :x2="zorgkantoorregio365_land" :xx2="jaar_tijdtotopname_365_land"
              :y2="dagen365_land" :name1="'365+ dagen/geen opname'" :x1="zorgkantoorregio366n_land"
              :xx1="jaar_tijdtotopname_365n_land" :y1="dagen366n_land" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz Verblijf'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="leveringsvorm_jaar" :y="percentage_natura_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight="400">
          <template slot="card-header">Leveringsvorm Wlz Verblijf</template>
          <div slot="card-body">
            Dit overzicht toont het landelijk gemiddelde van het percentage mensen met dementie dat langdurige zorg met
            huisvesting ontvangt, vergeleken met het totaal aantal mensen dat langdurige zorg ontvangt.
            <stacked-bar-chart-colors v-if="load.graphEight" :isLandelijk="false" :tickangle="0"
              :ytitle="'% Wlz met verblijf'" :xtitle="'Jaar'" :x="[leveringsvorm_jaar]" :y="[percentage_natura]"
              :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz volledig pakket thuis'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="leveringsvorm_jaar" :y="percentage_volledig_pakket_thuis_table.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Percentage'" :tableHeight="400">
          <template slot="card-header">Leveringsvorm Wlz volledig pakket thuis</template>
          <div slot="card-body">
            In dit overzicht wordt het landelijk gemiddelde weergegeven van het percentage mensen met dementie dat de
            Wlz in
            de vorm van een volledig pakket thuis krijgt, vergeleken met het totaal aantal mensen dat de Wlz ontvangt.
            <stacked-bar-chart-colors v-if="load.graphEight" :isLandelijk="false" :tickangle="0"
              :ytitle="'% met volledig pakket thuis'" :xtitle="'Jaar'" :x="[leveringsvorm_jaar]"
              :y="[percentage_volledig_pakket_thuis]" :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz modulair pakket thuis'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="leveringsvorm_jaar" :y="percentage_modulair_pakket_thuis_table.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Percentage'" :tableHeight="400">
          <template slot="card-header">Leveringsvorm Wlz modulair pakket thuis</template>
          <div slot="card-body">
            In dit overzicht wordt het landelijk gemiddelde weergegeven van het percentage mensen met dementie dat de
            Wlz in
            de vorm van een modulair pakket thuis krijgt, vergeleken met het totaal aantal mensen dat de Wlz ontvangt.
            <stacked-bar-chart-colors v-if="load.graphEight" :isLandelijk="false" :tickangle="0"
              :ytitle="'% met modulair pakket thuis'" :xtitle="'Jaar'" :x="[leveringsvorm_jaar]"
              :y="[percentage_modulair_pakket_thuis]" :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Leveringsvorm Wlz PGB'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="leveringsvorm_jaar" :y="percentage_pgb_table.map(value => `${value}%`)" :xtitle="'Regio naam'"
          :ytitle="'Percentage'" :tableHeight="400">
          <template slot="card-header">Leveringsvorm Wlz PGB</template>
          <div slot="card-body">
            In dit overzicht wordt het landelijk gemiddelde weergegeven van het percentage mensen met dementie dat de
            Wlz
            ontvangt in de vorm van een PGB, vergeleken met het totaal aantal mensen dat de Wlz
            ontvangt.
            <stacked-bar-chart-colors v-if="load.graphEight" :isLandelijk="false" :tickangle="0" :ytitle="'% met PGB'"
              :xtitle="'Jaar'" :x="[leveringsvorm_jaar]" :y="[percentage_pgb]" :showlegend="false" :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="factsheet2-landelijk-6">
        <card :cardId="'Tijd tussen opname en overlijden'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="jaar_tijd" :y="categorie_tijd" :z="percentage_tijd.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Categorie'" :ztitle="'Percentage'" :tableHeight="500">
          <template slot="card-header">Tijd tussen opname en overlijden</template>
          <div slot="card-body">
            In onderstaande grafiek is het landelijk gemiddelde weergegeven van de tijd tussen opname en overlijden. Er
            worden verschillende tijdsgroepen gebruikt. De verdeling van mensen met dementie over deze groepen blijft
            door
            de jaren heen redelijk constant.
            <stacked-bar-chart-colors-multi v-if="load.graphSeven" :tickangle="0" :isLandelijk='false' :height="360"
              :ytitle="'% aantal dagen'" :name5="'0-90 dagen'" :x5="zorgkantoorregionaam_90"
              :xx5="jaar_opnametotoverlijden_90" :y5="dagen_90" :name4="'91-180 dagen'" :x4="zorgkantoorregionaam_180"
              :xx4="jaar_opnametotoverlijden_180" :y4="dagen_180" :name3="'181-365 dagen'"
              :x3="zorgkantoorregionaam_365" :xx3="jaar_opnametotoverlijden_365" :y3="dagen_365"
              :name2="'366-730 dagen'" :x2="zorgkantoorregionaam_730" :xx2="jaar_opnametotoverlijden_730"
              :y2="dagen_730" :name1="'731+ dagen/niet overleden'" :x1="zorgkantoorregionaam_731n"
              :xx1="jaar_opnametotoverlijden_731n" :y1="dagen_731n" />
          </div>
        </card>
      </div>
    </div>

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />

  </div>
</template>

<script>

// import SideBarRight from '../components/SideBarRight'
import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import Card from '../components/Card'
import CardSmall from '../components/CardSmall'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'


export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Thema Langdurige Zorg',
  },
  components: { StackedBarChartColors, Card, CardSmall, StackedBarChartColorsMulti, Footer },
  data() {
    return {
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false,
        graphSix: false,
        graphSeven: false,
        graphEight: false,
      },
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      jaar_tijd: [],
      jaar_tijdtotopname: [],
      zorgkantoorregionaam_tijdtotopname: [],
      percentage_tijdtotopname: [],
      categorie_tijdtotopname: [],
      zorgkantoorregio42: [],
      dagen42: [],
      zorgkantoorregio91: [],
      dagen91: [],
      zorgkantoorregio365: [],
      dagen365: [],
      zorgkantoorregio180: [],
      dagen180: [],
      zorgkantoorregio366n: [],
      dagen366n: [],
      jaar_tijdtotopname_42: [],
      jaar_tijdtotopname_91: [],
      jaar_tijdtotopname_180: [],
      jaar_tijdtotopname_365: [],
      jaar_tijdtotopname_365n: [],

      zorgkantoorregio42_land: [],
      dagen42_land: [],
      zorgkantoorregio91_land: [],
      dagen91_land: [],
      zorgkantoorregio365_land: [],
      dagen365_land: [],
      zorgkantoorregio180_land: [],
      dagen180_land: [],
      zorgkantoorregio366n_land: [],
      dagen366n_land: [],
      jaar_tijdtotopname_42_land: [],
      jaar_tijdtotopname_91_land: [],
      jaar_tijdtotopname_180_land: [],
      jaar_tijdtotopname_365_land: [],
      jaar_tijdtotopname_365n_land: [],

      zorgkantoorregionaam_natura: [],
      zorgkantoorregionaam_vpt: [],
      zorgkantoorregionaam_mpt: [],
      zorgkantoorregionaam_pgb: [],
      percentage_natura: [],
      percentage_natura_table: [],
      percentage_pgb: [],
      percentage_pgb_table: [],
      percentage_volledig_pakket_thuis: [],
      percentage_volledig_pakket_thuis_table: [],
      percentage_modulair_pakket_thuis: [],
      percentage_modulair_pakket_thuis_table: [],
      leveringsvorm_jaar: [],
      verblijf: [],
      pgb: [],
      volledig_pakket_thuis: [],
      modulair_pakket_thuis: [],
      bevolkingsgroep: [],
      verblijf_j: [],
      pgb_j: [],
      volledig_pakket_thuis_j: [],
      modulair_pakket_thuis_j: [],
      jaar: [],
      zorgkantoorregionaam_tijd: [],
      categorie_tijd: [],
      percentage_tijd: [],
      zorgkantoorregionaam_90: [],
      dagen_90: [],
      zorgkantoorregionaam_180: [],
      dagen_180: [],
      zorgkantoorregionaam_365: [],
      dagen_365: [],
      zorgkantoorregionaam_730: [],
      dagen_730: [],
      zorgkantoorregionaam_731n: [],
      dagen_731n: [],
      zorgkantoorregionaamovo: [],
      jaar_opnametotoverlijden_90: [],
      jaar_opnametotoverlijden_180: [],
      jaar_opnametotoverlijden_365: [],
      jaar_opnametotoverlijden_730: [],
      jaar_opnametotoverlijden_731n: [],
      perc_ovo: [],
      percentage_pakket_thuis_table: [],
      categorie_pakket_thuis: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData(level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_tijdtotopname/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage - a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.zorgkantoorregio42 = response.data.filter(x => x.tijd_tot_opname == "0-42 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen42 = response.data.filter(x => x.tijd_tot_opname == "0-42 dagen").map(d => (d.percentage * 100))
            this.zorgkantoorregio91 = response.data.filter(x => x.tijd_tot_opname == "43-91 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen91 = response.data.filter(x => x.tijd_tot_opname == "43-91 dagen").map(d => (d.percentage * 100))
            this.zorgkantoorregio180 = response.data.filter(x => x.tijd_tot_opname == "92-180 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen180 = response.data.filter(x => x.tijd_tot_opname == "92-180 dagen").map(d => (d.percentage * 100))
            this.zorgkantoorregio365 = response.data.filter(x => x.tijd_tot_opname == "181-365 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen365 = response.data.filter(x => x.tijd_tot_opname == "181-365 dagen").map(d => (d.percentage * 100))
            this.zorgkantoorregio366n = response.data.filter(x => x.tijd_tot_opname == "> 365 dagen/geen opname").map(d => d.zorgkantoorregionaam)
            this.dagen366n = response.data.filter(x => x.tijd_tot_opname == "> 365 dagen/geen opname").map(d => (d.percentage * 100))

            this.zorgkantoorregionaam_tijdtotopname = response.data.map(d => d.zorgkantoorregionaam)
            this.percentage_tijdtotopname = response.data.map(d => (d.percentage * 100).toFixed(1))
            this.categorie_tijdtotopname = response.data.map(x => x.tijd_tot_opname)
            this.load.graphThree = true
          }
        ),
          this.$http.get(`/api/zorgkantoor_leveringsvorm/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a, b) => b.percentage_natura - a.percentage_natura)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.percentage_natura = response.data.map(d => (d.percentage_natura * 100))
              this.percentage_natura_table = response.data.map(d => (d.percentage_natura * 100).toFixed(1))
              this.zorgkantoorregionaam_natura = response.data.map(d => d.zorgkantoorregionaam)
              response.data.sort((a, b) => b.percentage_modulair_pakket_thuis - a.percentage_modulair_pakket_thuis)
              this.percentage_modulair_pakket_thuis = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100))
              this.percentage_modulair_pakket_thuis_table = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100).toFixed(1))
              this.zorgkantoorregionaam_mpt = response.data.map(d => d.zorgkantoorregionaam)
              response.data.sort((a, b) => b.percentage_pgb - a.percentage_pgb)
              this.percentage_pgb = response.data.map(d => (d.percentage_pgb * 100))
              this.percentage_pgb_table = response.data.map(d => (d.percentage_pgb * 100).toFixed(1))
              this.zorgkantoorregionaam_pgb = response.data.map(d => d.zorgkantoorregionaam)
              response.data.sort((a, b) => b.percentage_volledig_pakket_thuis - a.percentage_volledig_pakket_thuis)
              this.percentage_volledig_pakket_thuis = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100))
              this.percentage_volledig_pakket_thuis_table = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100).toFixed(1))
              this.zorgkantoorregionaam_vpt = response.data.map(d => d.zorgkantoorregionaam)
              this.load.graphOne = true
            }
          ),
          this.$http.get(`/api/zorgkantoor_opnametotoverlijden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a, b) => b.percentage - a.percentage)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.zorgkantoorregionaam_90 = response.data.filter(x => x.opname_tot_overlijden == "0-90 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_90 = response.data.filter(x => x.opname_tot_overlijden == "0-90 dagen").map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_180 = response.data.filter(x => x.opname_tot_overlijden == "91-180 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_180 = response.data.filter(x => x.opname_tot_overlijden == "91-180 dagen").map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_365 = response.data.filter(x => x.opname_tot_overlijden == "181-365 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_365 = response.data.filter(x => x.opname_tot_overlijden == "181-365 dagen").map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_730 = response.data.filter(x => x.opname_tot_overlijden == "366-730 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_730 = response.data.filter(x => x.opname_tot_overlijden == "366-730 dagen").map(d => (d.percentage * 100))
              this.zorgkantoorregionaam_731n = response.data.filter(x => x.opname_tot_overlijden == "langer dan 731 dagen/niet overleden").map(d => d.zorgkantoorregionaam)
              this.dagen_731n = response.data.filter(x => x.opname_tot_overlijden == "langer dan 731 dagen/niet overleden").map(d => (d.percentage * 100))

              this.zorgkantoorregionaam_tijd = response.data.map(d => d.zorgkantoorregionaam)
              this.categorie_tijd = response.data.map(x => x.opname_tot_overlijden)
              this.percentage_tijd = response.data.map(d => (d.percentage * 100).toFixed(1))
              this.load.graphFive = true
            }
          ),
          this.$http.get(`/api/zorgkantoor_overledenvooropname/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a, b) => b.percentage_overleden_voor_opname - a.percentage_overleden_voor_opname)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.perc_ovo = response.data.map(d => (d.percentage_overleden_voor_opname * 100))
              this.zorgkantoorregionaamovo = response.data.map(d => d.zorgkantoorregionaam)
              this.load.graphSix = true
            }
          ),
          this.$http.get(`/api/bevolking_leveringsvorm/get_data`).then(
            (response) => {
              response.data = response.data.filter(x => x.groep === 'Mensen met dementie')
              this.jaar = response.data.map(d => d.jaar)
              this.pgb_j = response.data.map(d => (d.percentage_pgb * 100))
              this.verblijf_j = response.data.map(d => (d.percentage_verblijf * 100))
              this.modulair_pakket_thuis_j = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100))
              this.volledig_pakket_thuis_j = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100))

              this.categorie_pakket_thuis = ['Verblijf', 'Volledig pakket thuis', 'Modulair pakket thuis', 'PGB Wlz']

              this.percentage_pakket_thuis_table = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100).toFixed(1))
              this.load.graphTwo = true
            }
          )
      }
      else if (level === 'landelijk') {
        this.$http.get(`/api/zorgkantoor_tijdtotopname/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a, b) => a.jaar - b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.zorgkantoorregio42_land = response.data.filter(x => x.tijd_tot_opname == "0-42 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen42 = response.data.filter(x => x.tijd_tot_opname == "0-42 dagen").map(d => (d.percentage))
            this.jaar_tijdtotopname_42_land = response.data.filter(x => x.tijd_tot_opname == "0-42 dagen").map(d => d.jaar)
            this.zorgkantoorregio91_land = response.data.filter(x => x.tijd_tot_opname == "43-91 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen91_land = response.data.filter(x => x.tijd_tot_opname == "43-91 dagen").map(d => (d.percentage))
            this.jaar_tijdtotopname_91_land = response.data.filter(x => x.tijd_tot_opname == "43-91 dagen").map(d => d.jaar)
            this.zorgkantoorregio180_land = response.data.filter(x => x.tijd_tot_opname == "92-180 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen180_land = response.data.filter(x => x.tijd_tot_opname == "92-180 dagen").map(d => (d.percentage))
            this.jaar_tijdtotopname_180_land = response.data.filter(x => x.tijd_tot_opname == "92-180 dagen").map(d => d.jaar)
            this.zorgkantoorregio365_land = response.data.filter(x => x.tijd_tot_opname == "181-365 dagen").map(d => d.zorgkantoorregionaam)
            this.dagen365_land = response.data.filter(x => x.tijd_tot_opname == "181-365 dagen").map(d => (d.percentage))
            this.jaar_tijdtotopname_365 = response.data.filter(x => x.tijd_tot_opname == "181-365 dagen").map(d => d.jaar)
            this.zorgkantoorregio366n_land = response.data.filter(x => x.tijd_tot_opname == "> 365 dagen/geen opname").map(d => d.zorgkantoorregionaam)
            this.dagen366n_land = response.data.filter(x => x.tijd_tot_opname == "> 365 dagen/geen opname").map(d => (d.percentage))
            this.jaar_tijdtotopname_365n_land = response.data.filter(x => x.tijd_tot_opname == "> 365 dagen/geen opname").map(d => d.jaar)

            this.jaar_tijdtotopname = response.data.map(d => d.jaar)
            this.categorie_tijdtotopname = response.data.map(x => x.tijd_tot_opname)
            this.percentage_tijdtotopname = response.data.map(d => (d.percentage * 100).toFixed(1))
            this.load.graphSeven = true
          }
        ),
          this.$http.get(`/api/zorgkantoor_leveringsvorm/get_data/?landelijk=True`).then(
            (response) => {
              response.data.sort((a, b) => a.jaar - b.jaar)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.percentage_natura = response.data.map(d => (d.percentage_natura * 100))
              this.percentage_natura_table = response.data.map(d => (d.percentage_natura * 100).toFixed(1))
              this.zorgkantoorregionaam_natura = response.data.map(d => d.zorgkantoorregionaam)
              this.leveringsvorm_jaar = response.data.map(d => d.jaar)

              this.percentage_modulair_pakket_thuis = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100))
              this.percentage_modulair_pakket_thuis_table = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100).toFixed(1))
              this.zorgkantoorregionaam_mpt = response.data.map(d => d.zorgkantoorregionaam)

              this.percentage_pgb = response.data.map(d => (d.percentage_pgb * 100))
              this.percentage_pgb_table = response.data.map(d => (d.percentage_pgb * 100).toFixed(1))
              this.zorgkantoorregionaam_pgb = response.data.map(d => d.zorgkantoorregionaam)

              this.percentage_volledig_pakket_thuis = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100))
              this.percentage_volledig_pakket_thuis_table = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100).toFixed(1))
              this.zorgkantoorregionaam_vpt = response.data.map(d => d.zorgkantoorregionaam)
              this.load.graphEight = true
            }
          ),
          this.$http.get(`/api/zorgkantoor_opnametotoverlijden/get_data/?landelijk=True`).then(
            (response) => {
              response.data.sort((a, b) => a.jaar - b.jaar)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.zorgkantoorregionaam_90 = response.data.filter(x => x.opname_tot_overlijden == "0-90 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_90 = response.data.filter(x => x.opname_tot_overlijden == "0-90 dagen").map(d => (d.percentage))
              this.jaar_opnametotoverlijden_90 = response.data.filter(x => x.opname_tot_overlijden == "0-90 dagen").map(d => d.jaar)

              this.zorgkantoorregionaam_180 = response.data.filter(x => x.opname_tot_overlijden == "91-180 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_180 = response.data.filter(x => x.opname_tot_overlijden == "91-180 dagen").map(d => (d.percentage))
              this.jaar_opnametotoverlijden_180 = response.data.filter(x => x.opname_tot_overlijden == "91-180 dagen").map(d => d.jaar)

              this.zorgkantoorregionaam_365 = response.data.filter(x => x.opname_tot_overlijden == "181-365 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_365 = response.data.filter(x => x.opname_tot_overlijden == "181-365 dagen").map(d => (d.percentage))
              this.jaar_opnametotoverlijden_365 = response.data.filter(x => x.opname_tot_overlijden == "181-365 dagen").map(d => d.jaar)


              this.zorgkantoorregionaam_730 = response.data.filter(x => x.opname_tot_overlijden == "366-730 dagen").map(d => d.zorgkantoorregionaam)
              this.dagen_730 = response.data.filter(x => x.opname_tot_overlijden == "366-730 dagen").map(d => (d.percentage))
              this.jaar_opnametotoverlijden_730 = response.data.filter(x => x.opname_tot_overlijden == "366-730 dagen").map(d => d.jaar)


              this.zorgkantoorregionaam_731n = response.data.filter(x => x.opname_tot_overlijden == "langer dan 731 dagen/niet overleden").map(d => d.zorgkantoorregionaam)
              this.dagen_731n = response.data.filter(x => x.opname_tot_overlijden == "langer dan 731 dagen/niet overleden").map(d => (d.percentage))
              this.jaar_opnametotoverlijden_731n = response.data.filter(x => x.opname_tot_overlijden == "langer dan 731 dagen/niet overleden").map(d => d.jaar)

              this.jaar_tijd = response.data.map(d => d.jaar)
              this.categorie_tijd = response.data.map(x => x.opname_tot_overlijden)
              this.percentage_tijd = response.data.map(d => (d.percentage * 100).toFixed(1))
              this.load.graphSeven = true
            }
          ),
          this.$http.get(`/api/bevolking_leveringsvorm/get_data`).then(
            (response) => {
              response.data = response.data.filter(x => x.groep === 'Mensen met dementie')
              this.jaar = response.data.map(d => d.jaar)
              this.pgb_j = response.data.map(d => (d.percentage_pgb * 100))
              this.verblijf_j = response.data.map(d => (d.percentage_verblijf * 100))
              this.modulair_pakket_thuis_j = response.data.map(d => (d.percentage_modulair_pakket_thuis * 100))
              this.volledig_pakket_thuis_j = response.data.map(d => (d.percentage_volledig_pakket_thuis * 100))
              this.load.graphTwo = true
            }
          )
      }
    }
  },
  mounted() {
    this.loadAllData(this.selectedLevel)
  }
}

</script>

<style scoped>
.custom-link {
  margin-right: 0px !important;
  outline: none;
  text-decoration: none;
  padding: 3px 3px 3px;
}


.intro-section-colour-block {
  background: #e3e7ed;
  width: 100%;
  padding: 5% 8% 8% 5%;
  color: #fff;
  position: relative;
  border: 5px solid #e3e7ed;
  border-radius: 15px;
}

.intro-section-cc {
  width: 100%;
  height: 100px;
  position: relative;
  left: 0px;
  background: linear-gradient(to right bottom, #e3e7ed 49%, #e3e7ed 50%),
    linear-gradient(-50deg, #ffffff 16px, #000 0);
  margin-top: -100px;
  border-radius: 15px;
}

.intro-section-title {
  font-weight: bold;
  margin-bottom: 2rem;
}

/*.intro-section-text{
  color: #1f2a44;
}*/
</style>